import React from 'react'
import useForm from '../utils/useForm'
import useProduct from '../utils/useProduct'

function ErrorMsg({ message }) {
  return (
    <div className="w-full text-pxred bg-red-100 border border-pxred font-medium p-4 mt-4 text-center rounded-lg">
      {message}
    </div>
  )
}

export default function FormProduct({ product, points, slug }) {
  const { values, updateValue } = useForm({
    name: '',
    userId: '',
    email: '',
    product,
    points,
    slug,
    mappleSyrup: '',
  })
  const { submitProduct, success, error, loading, message } = useProduct({
    values,
  })

  return (
    <section className="w-full bg-gray-200">
      <div className="w-11/12 lg:w-4/6 mx-auto py-12">
        <h3 className="font-bold block text-pxblue-700 text-4xl">Solicitud</h3>
        {!success ? (
          <form onSubmit={submitProduct} className="w-full">
            <fieldset disabled={loading} className="flex flex-wrap">
              <label htmlFor="name" className="w-full my-2">
                <span className="w-full block text-pxblue-700 px-4 py-2 text-xl">
                  Nombre del colaborador
                </span>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={updateValue}
                  className="bg-white w-full rounded-3xl px-4 py-2 focus:ring-2 focus:ring-pxblue-400"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
              </label>
              <label htmlFor="userId" className="w-full my-2">
                <span className="w-full block text-pxblue-700 px-4 py-2 text-xl">
                  ID del colaborador
                </span>
                <input
                  type="text"
                  name="userId"
                  id="userId"
                  value={values.userId}
                  onChange={updateValue}
                  className="bg-white w-full rounded-3xl px-4 py-2 focus:ring-2 focus:ring-pxblue-400"
                />
              </label>
              <label htmlFor="email" className="w-full my-2">
                <span className="w-full block text-pxblue-700 px-4 py-2 text-xl">
                  Correo institucional
                </span>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={updateValue}
                  className="bg-white w-full rounded-3xl px-4 py-2 focus:ring-2 focus:ring-pxblue-400"
                />
              </label>
              <div className="w-full text-center pt-4">
                <button
                  type="submit"
                  className="text-white bg-pxblue-700 rounded-2xl text-lg px-10 py-2 inline-block focus:ring-4 focus:ring-pxblue-400"
                  disabled={loading}
                >
                  {loading ? 'Enviando' : 'Enviar Solicitud'}
                  {loading ? <span className="spinner" /> : null}
                </button>
              </div>
            </fieldset>
            {error ? <ErrorMsg message={message} /> : null}
          </form>
        ) : (
          <div className="w-full text-pxblue bg-pxblue-200 border border-pxblue font-medium p-4 mt-4 text-center rounded-lg">
            Solicitud enviada con exito, nos pondremos en contacto contigo.
          </div>
        )}
      </div>
    </section>
  )
}
