import { useState } from 'react'

export default function useProduct({ values }) {
  const [error, setError] = useState()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const token =
    typeof window !== 'undefined' ? localStorage.getItem(`token-user`) : ''
  async function submitProduct(e, data) {
    e.preventDefault()
    setLoading(true)
    setError(null)
    let body = {}
    if (!data) {
      body = {
        name: values.name,
        email: values.email,
        userId: values.userId,
        product: values.product,
        points: values.points,
        slug: values.slug,
      }
    }
    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/functions/product/placeProduct`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
        body: JSON.stringify(body),
      }
    )
    const text = JSON.parse(await res.text())
    if (res.status >= 400 && res.status < 600) {
      setLoading(false)
      setMessage(text.message)
      setError(true)
    } else {
      setLoading(false)
      setMessage('')
      setError(false)
      setSuccess(true)
    }
  }

  return {
    submitProduct,
    success,
    error,
    loading,
    message,
  }
}
