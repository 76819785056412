import React, { useContext, useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, Link } from 'gatsby'

import AppContext from '../components/AppContext'
import ProductsList from '../components/ProductsList'
import FormProduct from '../components/FormProduct'
import SEO from '../components/SEO'

import isAuthenticated from '../utils/isAuthenticated'
import { setRedirect } from '../utils/setRedirect'

const PremioMain = ({ premio, relacionados }) => {
  const [formActive, setFormActive] = useState(false)
  return (
    <>
      <section className="w-4/5 xl:w-4/6 mx-auto">
        <div className="flex flex-nowrap flex-col justify-center py-12 md:flex-wrap md:flex-row">
          <div className="PremioImage flex justify-center lg:w-7/12 lg:mr-10">
            <div className="PremioImage">
              <GatsbyImage
                image={premio.nodes[0].image.asset.gatsbyImageData}
                alt=""
                className="w-full object-cover"
              />
            </div>
          </div>
          <div className="w-full lg:w-5/12 pt-6">
            <h1 className="text-pxblue-700 text-xl font-bold my-2">
              {premio.nodes[0].name || ''}
            </h1>
            <p className="text-pxtx-200 text-sm">
              {premio.nodes[0].description || ''}
            </p>
            <span className="block text-pxblue-700 font-bold my-4 text-2xl tracking-wider">
              {premio.nodes[0].points ? `${premio.nodes[0].points} Puntos` : ''}
            </span>
            <div className="w-full text-center pt-4">
              <button
                type="button"
                className="text-white bg-pxblue-700 rounded-lg px-10 py-2 inline-block  hover:bg-pxblue-600 hover:shadow-xl"
                onClick={() => setFormActive(!formActive)}
              >
                Obtener
              </button>
            </div>
          </div>
        </div>
      </section>
      {formActive ? (
        <FormProduct
          product={premio.nodes[0].name}
          points={premio.nodes[0].points}
          slug={premio.nodes[0].slug.current}
        />
      ) : null}
      <section className="w-4/5 xl:w-4/6 mx-auto">
        <div className="py-12">
          <div className="mx-auto my-4 border-t border-pxred">
            <h3 className="font-bold block text-pxblue-700 text-4xl pt-10">
              Otros productos
            </h3>
          </div>
          <div className="flex flex-wrap items-center">
            {relacionados.length > 0 ? (
              <ProductsList premios={relacionados} />
            ) : (
              <p>No hay premios</p>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

async function checkTocken(cb) {
  const validToken = await isAuthenticated('user')
  cb(validToken)
}

export default function PremioPage({ data: { premio, relacionado } }) {
  const { user } = useContext(AppContext)
  const [loggedUser, setLoggedUser] = user
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (loggedUser) {
        checkTocken(setLoggedUser)
      } else {
        // eslint-disable-next-line no-restricted-globals
        setRedirect(location ? location.href : '')
        // navigate('/login')
      }
    }
  })

  const relacionados = relacionado.nodes.slice(0, 3).map(function () {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.splice(Math.floor(Math.random() * this.length), 1)[0]
  }, relacionado.nodes.slice())

  return (
    <main>
      <SEO title={premio.nodes[0].name} />
      {loggedUser ? (
        <PremioMain premio={premio} relacionados={relacionados} />
      ) : (
        <p className="w-11/12 mx-auto mt-10 md:w-1/2 lg:w-1/3 border-pxblue-600 border p-4 bg-gray-100 rounded-md text-pxtx-200 text-center">
          <Link to="/login" className="font-medium text-pxblue underline">
            Inicia sesión
          </Link>{' '}
          para ver el contenido
        </p>
      )}
    </main>
  )
}

export const query = graphql`
  query($slug: String!, $cat: String!) {
    premio: allSanityPremio(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        name
        description
        points
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
    relacionado: allSanityPremio(
      filter: { model: { elemMatch: { slug: { current: { eq: $cat } } } } }
    ) {
      nodes {
        name
        description
        points
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
